import React from 'react';

const Footer = () => {
    return (
        <div className="container-fluid footer">
            <div className="container">
                <div className="row py-2">
                    <div className="col">
                        This ranking software was brought to you by Andreas Hejndorf
                    </div>
                    <div className="col">
                        I am the second column
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;